
.img-preview-dialog {
    .img-preview {
        display: block;
        max-width: 100%;
    }
}
::v-deep .el-breadcrumb {
    .el-breadcrumb__inner {
        color: #000;
    }
}
.quiz-container {
    width: 100%;
    height: calc(100% - 40px);
    padding: 20px 0;
    .quiz-content {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .quiz-list {
            display: flex;
            flex-direction: column;
            height: 1%;
            flex: 1;
            background: #fff;
            margin-top: 20px;
            .discuss-title {
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #EAEAEA;
                padding: 0 20px;
            }
            .discuss-detail {
                height: 1%;
                flex: 1;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .item {
                    display: flex;
                    padding: 10px 20px;
                    border-bottom: 1px solid #EAEAEA;
                    .item-left {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        border: 1px solid #EAEAEA;
                        overflow: hidden;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }
                    .item-right {
                        margin-left: 10px;
                        width: 1%;
                        flex: 1;
                        .item-name {
                            line-height: 19px;
                            color: #666;
                        }
                        .item-content {
                            line-height: 19px;
                            margin-top: 4px;
                            ::v-deep img {
                                display: block;
                                max-width: 300px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}
